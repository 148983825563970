import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Box, ButtonBase} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import StatusLight from "./ProfileSection/Status";


// assets
import {IconMenu2} from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({handleLeftDrawerToggle}) => {
    const theme = useTheme();


    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 228,
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{display: {xs: 'none', md: 'block'}}}>
                    <LogoSection/>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <ButtonBase sx={{borderRadius: '12px', overflow: 'hidden'}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem"/>
                        </Avatar>
                    </ButtonBase>
                </Box>
            </Box>

            {/* header search */}
            <Box sx={{flexGrow: 1}}/>
            <StatusLight/>

            {/* notification & profile */}
            <ProfileSection/>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    hasValidCredentials: PropTypes.bool

};

export default Header;
