import React, {useContext, useEffect, useState} from 'react';

// material-ui
import {Button, Dialog, DialogActions, DialogContent, Grid, Typography} from "@mui/material";

// project imports
import TransactionSummary from "../ui-component/TransactionSummary";
import RefreshQueryToolbar from "../ui-component/datagrid/RefreshQueryToolbar";
import {useAuthHeaders} from "./AuthHeaders";
import {transactionHistoryColumns} from "../ui-component/datagrid/transactionHistoryColumns";
import ApiUrlContext from "../ApiUrlContext";
import CircularSpinner from "../ui-component/CircularSpinner";

// third-party
import axios from 'axios';
import {Form, Formik} from "formik";
import LedgerDropdown from "./LedgerDropdown";
import {getTomorrow} from "../store/dateStore";
import {StyledDataGrid} from "../ui-component/datagrid/StyledDataGrid";


// ============================|| Transaction History Page  ||============================ //

const Transactions = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [selectedLedger, setSelectedLedger] = useState(null);
    const apiUrl = useContext(ApiUrlContext);
    const authHeaders = useAuthHeaders();


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post(`${apiUrl}/query`, {
                    ledgerId: selectedLedger,
                    begin: '2022-01-01',
                    end: getTomorrow(),
                    detailed: "true"
                }, {
                    headers: authHeaders,
                });
                const processedTransactions = (response.data.ledger?.transactions || [])
                setData(processedTransactions.reverse());
            } catch (error) {
                if (error?.response?.data?.error?.code === "401") {
                    console.error("Authentication error. Please log in again.");
                } else {
                    console.error("An error occurred:", error);
                }
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [refreshFlag, selectedLedger]);

    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };

    const handleRowClick = (param) => {
        setSelectedTransaction(param.row);
        setModalOpen(true);
    };
    return (
        <div style={{height: 600, width: '100%', marginTop: "2vh"}}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={6}>
                    <Typography variant={"h2"} sx={{mb: 2}}>Transaction History</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Formik
                        initialValues={{
                            ledgerId: '',
                        }}
                        onSubmit={(values) => {
                            setSelectedLedger(values.ledgerId);
                        }}
                    >
                        {({values, setFieldValue, submitForm}) => (
                            <Form style={{marginBottom: 25}}>
                                <LedgerDropdown
                                    name="ledgerId"
                                    label="Select Ledger"
                                    maxHeight={'50vh'}
                                    textSize={"1rem"}
                                    value={values.ledgerId}
                                    onChange={(event) => {
                                        setFieldValue('ledgerId', event.target.value);
                                        submitForm();
                                    }}
                                />
                            </Form>
                        )
                        }
                    </Formik>
                </Grid>
            </Grid>
            {
                loading ? (
                    <CircularSpinner/>
                ) : (
                    <>
                        <StyledDataGrid
                            rows={data}
                            columns={transactionHistoryColumns}
                            getRowId={(row) => {
                                const transactionType = Object.keys(row)[0];
                                return row[transactionType].id;
                            }}
                            slots={{
                                toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh}/>,
                            }}
                            onRowClick={handleRowClick} // Updated here
                            sx={{
                                boxShadow: 5,
                                border: 2,
                                borderColor: 'primary.light',
                                backgroundColor: '#fff',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                            }}
                        />
                        <Dialog
                            open={isModalOpen}
                            onClose={() => setModalOpen(false)}
                            fullWidth
                            maxWidth={"md"}
                        >
                            <DialogContent>
                                <TransactionSummary selectedTransaction={selectedTransaction}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setModalOpen(false)} color="secondary" variant={"contained"}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
        </div>
    )
        ;
}

export default Transactions;
