// assets
import {IconHelp, IconKey} from '@tabler/icons';

// constant
const icons = {
  IconKey,
  IconHelp
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: '',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'authentication',
      title: 'Authentication',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'login3',
          title: 'Login',
          type: 'item',
          url: '/authentication',
          target: false
        }
        // {
        //   id: 'register3',
        //   title: 'Register',
        //   type: 'item',
        //   url: '/pages/register/register3',
        //   target: true
        // }
      ]
    },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://mozrt.readme.io/reference/getting-started-with-your-api-1',
      icon: icons.IconHelp,
      external: true,
      target: true
    }
  ]
};

export default pages;
