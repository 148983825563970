import {Box, Fade, Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

export const ResponseDisplay = ({response}) => {
    const theme = useTheme();

    if (!response || response.error) {
        return (
            <>
                <Fade in={!!response?.error}>
                    <Typography variant="h4" sx={{color: theme.palette.error.main}}>
                        Request Error
                    </Typography>
                </Fade>
                <Typography sx={{mt: 1}} variant="h6">
                    {response?.error?.message || null}
                </Typography>
            </>
        );
    }

    const renderProperty = (property, value) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // If the value is an array, render its items
                return (
                    <Box key={property}>
                        <Typography variant="body1">
                            <strong>{property}:</strong>
                        </Typography>
                        {value.map((item, index) => (
                            <Typography variant="body1" key={index}>
                                {item}
                            </Typography>
                        ))}
                    </Box>
                );
            } else {
                // If the value is an object, recursively render its properties
                return (
                    <Box key={property}>
                        <Typography variant="body2">
                            <strong>{property}</strong>
                        </Typography>
                        {Object.entries(value).map(([nestedProp, nestedValue]) =>
                            renderProperty(nestedProp, nestedValue)
                        )}
                    </Box>
                );
            }
        }

        // If the value is not an object or array, render the property and its value
        return (
            <Typography variant="body1" key={property}>
                <strong>{property}:</strong> {value}
            </Typography>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Response Information
            </Typography>
            {typeof response === 'object' && true
                ? Object.entries(response).map(([property, value]) => renderProperty(property, value))
                : null}
        </Box>
    );
};