import React, {useContext, useEffect, useState} from "react";

// material-ui
import {Button, Grid, Typography} from "@mui/material";

// project imports
import {AuthContext, handleRemoveLocalStorage, parsedCredentials} from "./requests/auth/AuthContext";
import AnimateButton from "./ui-component/extended/AnimateButton";
import {useNavigate} from "react-router-dom";
import SuccessToast from "./ui-component/alerts/SuccessToast";
import SiteLabelContext from "./SiteLabelContext";

const Welcome = () => {
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const siteLabel = useContext(SiteLabelContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const reloaded = urlParams.get('reloaded');
        if (!reloaded) {
            const newUrl = `${window.location.pathname}?reloaded=true`;
            window.location.assign(newUrl);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            setShowSuccessToast(true);
            const timer = setTimeout(() => {
                setShowSuccessToast(false);
            }, 5000); // Set the time in milliseconds after which the toast will close

            return () => clearTimeout(timer);
        }
    }, [isAuthenticated]);

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} lg={6}>
                {isAuthenticated ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={{mt: 5, mb: 2}}>
                                Welcome to the self serve portal
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" sx={{mb: 2}}>
                                You're logged in as <span style={{ color: '#C71B8C' }}>{parsedCredentials?.username}</span>
                                <br/>
                                In the <span style={{ color: '#EC8628' }}>{siteLabel} Environment</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    sx={{width: '100%'}}
                                    onClick={() => {
                                        handleRemoveLocalStorage();
                                        setIsAuthenticated(false);
                                        navigate("/authentication");
                                    }}
                                >
                                    Logout
                                </Button>
                            </AnimateButton>
                        </Grid>
                        <SuccessToast
                            message={'You have successfully logged in'}
                            open={showSuccessToast}
                            onClose={() => setShowSuccessToast(false)}
                        />
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h2" sx={{mt: 5, mb: 1}}>
                                You're not logged in
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: '100%'}}
                                    onClick={() => {
                                        navigate("/authentication");
                                    }}
                                >
                                    Login
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Welcome;
