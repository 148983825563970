import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createRoot} from 'react-dom/client';
import App from 'App';
import {store} from 'store';
import 'assets/scss/style.scss';
import ApiUrlContext from './ApiUrlContext';
import SiteLabelContext from './SiteLabelContext';
import APIRequestHeader from "./APIRequestHeader";

function Main() {
    const [apiUrl, setApiUrl] = useState(null);
    const [siteLabel, setSiteLabel] = useState(null);
    const [apiRequestHeader, setApiRequestHeader] = useState(null);
    const [error, setError] = useState(false)

    useEffect(() => {
        fetch("/webConfig.json")
            .then(response => response.json())
            .then(data => {
                setApiUrl(data.API_URL);
                setSiteLabel(data.SITE_LABEL);
                setApiRequestHeader(data.API_REQUEST_HEADER);
            })
            .catch(error => {
                console.error("Error fetching the config:", error);
                setError(true)
            });
    }, []);

    if (error) {
        return <div>Error: Config is missing.</div>;
    }

    return (
        <ApiUrlContext.Provider value={apiUrl}>
            <SiteLabelContext.Provider value={siteLabel}>
                <APIRequestHeader.Provider value={apiRequestHeader}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </Provider>
                </APIRequestHeader.Provider>
            </SiteLabelContext.Provider>
        </ApiUrlContext.Provider>
    );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Main/>);