import React from "react";

// material-ui
import {Grid} from "@mui/material";
// project imports
import ApiRequestCard from "./ui-component/cards/ApiRequestCard";
import GetFxQuote from "./requests/GetFxQuote";
// third-party

// ============================|| PAGE - Quote ||============================ //
const Quote = () => {

    return (
        <Grid container spacing={2} justifyContent="center">
            <ApiRequestCard
                title={"Get an FX Quote"}
                buttonText={"Get FX Quote"}
                component={<GetFxQuote/>}
                description={"  This request will get you an FX rate"}
            />
        </Grid>

    );
};

export default Quote;

