// assets
import {IconCoins, IconDashboard, IconScale, IconUserSearch, IconWallet, IconFileInvoice} from '@tabler/icons';

// constant
const icons = {IconDashboard, IconUserSearch, IconWallet, IconCoins, IconScale, IconFileInvoice};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'entity',
            title: 'Entity',
            type: 'item',
            url: '/entity',
            icon: icons.IconUserSearch,
            breadcrumbs: false
        },
        {
            id: 'ledger',
            title: 'Ledger',
            type: 'item',
            url: '/ledger',
            icon: icons.IconScale,
            breadcrumbs: false
        },
        {
            id: 'transactionHistory',
            title: 'Transaction History',
            type: 'item',
            url: '/transaction-history',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
