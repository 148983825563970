// material-ui
import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.svg';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    <img src={logo} alt="Mozrt" width="150" />

  );
};

export default Logo;
