import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SuccessToast = ({ open, onClose, message }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert
                severity="success"
                sx={{
                    width: '100%',
                    backgroundColor: '#27ab09',
                    color: '#FFFFFF',
                    '& .MuiAlert-icon': {
                        color: '#FFFFFF'
                    }
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SuccessToast;