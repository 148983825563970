import React from "react";
import {Chip} from "@mui/material";

export const transactionHistoryColumns = [
    {
        field: 'transactionType',
        headerName: 'Type',
        valueGetter: (params) => Object.keys(params.row)[0],
    },
    {
        field: 'reference',
        headerName: 'Reference',
        flex: 1,
        valueGetter: (params) => {
            const transactionType = Object.keys(params.row)[0];
            return params.row[transactionType].reference || '-';
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        flex: 0.75,
        valueGetter: (params) => {
            const transactionType = Object.keys(params.row)[0];
            const transaction = params.row[transactionType];
            return `${transaction.amount} ${transaction.currency}`;
        },
    },
    {
        field: 'fxAmountWithCurrency',
        headerName: 'FX Amount',
        flex: 0.5,
        valueGetter: (params) => {
            const transactionType = Object.keys(params.row)[0];
            const fx = params.row[transactionType].fx;

            if (fx && fx.amount && fx.currency) {
                return `${fx.amount} ${fx.currency}`;
            }

            return '';
        },
    },
    {
        field: 'created',
        headerName: 'Date',
        flex: 1,
        valueGetter: (params) => {
            const transactionType = Object.keys(params.row)[0];
            const dateString = params.row[transactionType].created;

            const date = new Date(dateString);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
    },
    {
        field: 'state',
        headerName: 'Status',
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
            const transactionType = Object.keys(params.row)[0];
            const state = params.row[transactionType].state;
            return <Chip label={state} />;
        },
    },
];