import React from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from '@mui/x-data-grid'; // Import GridToolbarExport if you want the export button
import RefreshIcon from '@mui/icons-material/Refresh';
import {Button} from "@mui/material";

const RefreshQueryToolbar = ({onRefresh}) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport/>
            {/* Your custom Refresh button */}
            <Button
                onClick={onRefresh}
                size="small"
                startIcon={<RefreshIcon/>}
                sx={{color: '#3B9FF5', marginLeft: '16px', textTransform: 'none'}}
            >
                Refresh
            </Button>
        </GridToolbarContainer>
    );
}

export default RefreshQueryToolbar;
