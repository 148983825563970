import React, {useContext, useEffect, useState} from 'react';
// materialUI imports
import {Box, Grid, Typography} from '@mui/material';

// project imports
import CustomFormField from '../ui-component/CustomFormField';
import CustomDropdown from '../ui-component/CustomDropdown';
import {useAuthHeaders} from "./AuthHeaders";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import CustomCurrencyValueInput from "../ui-component/CustomCurrencyValueInput";

// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {ResponseDisplay} from "./response/ResponseDisplay";
import ApiUrlContext from "../ApiUrlContext";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import LedgerDropdown from "./LedgerDropdown";


// ============================|| Create - Debit ||============================ //

const CreateDebit = ({entity}) => {
    const responseRef = React.useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();

    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [response]);
    const InfoText = ({text}) => showInfo ?
        <Typography variant="caption" sx={{color: '#C41C90'}}>
            {text}
        </Typography> : null;


    const handleSubmit = async (values, {setSubmitting}) => {
        const requestData = {
            when: values.when,
            text: {
                description: values.description,
            },
            reference: values.reference,
            ledgerId: values.ledgerId,
            account: values.account,
            amount: values.amount,
            currency: values.currency,
            entityId: values.entityId
        };

        if (values.identifier) {
            requestData.text.identifier = values.identifier;
        }

        try {
            const options = {
                method: 'POST',
                url: `${apiUrl}/debit`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            console.log('Response:', response.data);
            setResponse(response.data);
            setIsSubmitted(true);
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box mt={2}>
            <div
                onClick={() => setShowInfo(prev => !prev)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '5px'
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                    Need Help?
                </Typography>
            </div>

            <Formik
                initialValues={{
                    when: 'nextday',
                    description: '',
                    reference: '',
                    ledgerId: '',
                    account: 1,
                    amount: '',
                    currency: 'USD',
                    entityId: entity ? entity.reference : ""
                }}
                validationSchema={Yup.object().shape({
                    when: Yup.string().required('When is required'),
                    identifier: Yup.string(),
                    description: Yup.string().required('Description is required'),
                    reference: Yup.string().required('Reference is required'),
                    account: Yup.string(),
                    amount: Yup.string().required('Amount is required'),
                    currency: Yup.string().required('Currency is required'),
                    entityId: Yup.string().required('Entity Reference is required'),
                })}
                onSubmit={handleSubmit}
            >
                {({values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors, setFieldValue}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <InfoText text={'The Entity Reference of the payer'}/>
                                <CustomFormField
                                    name="entityId"
                                    label="Entity Reference"
                                    value={values.entityId}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{mt:1}}>
                                <InfoText text={'If specified, the ledger ID that should be used for this request.'}/>
                                <LedgerDropdown
                                    name="ledgerId"
                                    label="ledgerId"
                                    value={values.ledgerId}
                                    onChange={(event) => setFieldValue('ledgerId', event.target.value)}
                                    maxHeight={"50vh"}
                                    textSize={"1rem"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoText
                                    text={'The raw account number (within the ledger) that should be used for this request. This should only be specified for pooled ledgers. If not leave as one'}/>
                                <CustomFormField
                                    name="account"
                                    label="Account"
                                    value={values.account}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                    name="when"
                                    label="When"
                                    value={values.when}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'standard', label: 'Standard'},
                                        {value: 'sameday', label: 'Same Day'},
                                    ]}
                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <CustomCurrencyValueInput
                                    name="amount"
                                    label="Amount"
                                    value={values.amount}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                    name="currency"
                                    label="Currency"
                                    value={values.currency}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'USD', label: 'USD'},
                                        // Add more currency options as needed
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InfoText text={"A unique reference for this request. This is used to ensure\n" +
                                    "idempotency. If a request is received with a duplicate\n" +
                                    "reference, it will not be applied twice; you will receive a\n" +
                                    "success response and the latest state."}/>.
                                <CustomFormField
                                    name="reference"
                                    label="Reference"
                                    value={values.reference}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="identifier"
                                    label="Identifier"
                                    value={values.identifier}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InfoText text={'A short description of the transaction.'}/>
                                <CustomFormField
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <APIRequestButton isSubmitting={isSubmitting} isSubmitted={isSubmitted}
                                                  showRefreshButton={true}/>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2} ref={responseRef}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateDebit;
