import React, {useContext, useState} from 'react';
// materialUI imports
import {Box, Typography} from '@mui/material';

// project imports
import {useAuthHeaders} from "./AuthHeaders";
import ApiUrlContext from "../ApiUrlContext";
import {ResponseDisplay} from "./response/ResponseDisplay";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import CustomFormField from "../ui-component/CustomFormField";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';

// ============================|| Query - credit ||============================ //

const CreditQuerySection = () => {
        const [response, setResponse] = useState(null);
        const apiUrl = useContext(ApiUrlContext);
        const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();


        const handleSubmit = async (values) => {
            const requestData = {
                creditReference: values.creditReference,
            };

            try {
                const response = await axios.post(`${apiUrl}/query`, requestData, {
                    headers: authHeaders,
                });
                console.log('Response:', response.data);
                setResponse(response.data);
            } catch (error) {
                if (error.response && error.response.data.error.code === "401") {
                    // Handle 401 Unauthorized response
                    setIsAuthenticated(false);
                    handleRemoveLocalStorage();
                }
                console.error('Error:', error);
            }
        };

        return (
            <Box mt={2}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Query credit - Please enter the credit ID
                </Typography>

                <Formik
                    initialValues={{
                        creditReference: '',
                    }}
                    validationSchema={Yup.object().shape({
                        creditReference: Yup.string().required('credit ID is required'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            <CustomFormField
                                name="creditReference"
                                label="Credit Reference"
                                value={values.creditReference}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                            <APIRequestButton isSubmitting={isSubmitting}/>
                        </Form>
                    )}
                </Formik>


                {response && (
                    <Box mt={2}>
                        <ResponseDisplay response={response}/>
                    </Box>
                )}
            </Box>
        );
    }
;

export default CreditQuerySection;