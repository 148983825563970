import React, {useState} from "react";

// material-ui
import {Grid} from "@mui/material";

// project imports
import CreateCredit from "./requests/CreateCredit";

// third-party
import QueryCredit from "./requests/QueryCredit";
import ApiRequestCard from "./ui-component/cards/ApiRequestCard";

// ============================|| PAGE - Credit ||============================ //
const Credit = () => {
    const [isHidden, setIsHidden] = useState(true); // state to control hidden section
    const [isHidden2, setIsHidden2] = useState(true); // state to control hidden section

    const handleToggleHiddenSection = () => {
        setIsHidden(!isHidden);
    };
    const handleToggleHiddenSection2 = () => {
        setIsHidden2(!isHidden2);
    };


    return (
        <Grid container spacing={2} justifyContent="center">
            <ApiRequestCard
                title={"Look Up An Credit"}
                buttonText={"Search Credits"}
                component={<QueryCredit/>}
                description={"  This request will produce a response containing the latest\n" +
                    "                                    details related to the specified credit."}
            />
            <ApiRequestCard
                title={"Create A Credit"}
                buttonText={"Initiate Credit"}
                component={<CreateCredit/>}
                description={" This request will start the process of crediting funds to a payee (the \n" +
                    "specified entity) from the payer (the client). This will be executed \n" +
                    "using the most appropriate method between the two parties within the\n" +
                    "specified timeframe."}
            />
        </Grid>
    );
};

export default Credit;

