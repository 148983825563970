import {useTheme} from "@mui/material/styles";
import {AuthContext, handleRemoveLocalStorage, parsedCredentials, storedCredentials} from "./AuthContext";
import React, {useContext, useState} from "react";
import ApiUrlContext from "../../ApiUrlContext";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import CircularSpinner from "../../ui-component/CircularSpinner";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import AnimateButton from "../../ui-component/extended/AnimateButton";
import {Formik} from "formik";
import ErrorToast from "../../ui-component/alerts/ErrorToast";
import * as Yup from "yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import SiteLabelContext from "../../SiteLabelContext";


// ============================|| Authentication By API  - LOGIN ||============================ //

const AuthenticationLogin = ({history, ...others}) => {
    const theme = useTheme();
    const navigate = useNavigate();  // Add this line
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const apiUrl = useContext(ApiUrlContext);
    const siteLabel = useContext(SiteLabelContext);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);


    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


    const handleAuthentication = (values, formikHelpers) => { // Add formikHelpers as a parameter
        setLoading(true);
        delay(1500)
            .then(() => {
                const encodedCredentials = btoa(`${values.userName}:${values.password}`);
                return axios.post(
                    `${apiUrl}/query`,
                    {},
                    {
                        headers: {Authorization: `Basic ${encodedCredentials}`}
                    }
                );
            })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    localStorage.setItem('authCredentials', JSON.stringify({
                        username: values.userName,
                        password: values.password
                    }));
                    setTimeout(() => {
                        setIsAuthenticated(true);
                        navigate("/welcome");
                    }, 500);
                } else {
                    formikHelpers.setSubmitting(false);
                    formikHelpers.resetForm(); // Reset form values and errors
                    return Promise.reject(new Error("Authentication failed"));
                }
            })
            .catch((err) => {
                console.error(err);
                setShowErrorToast(true);
                formikHelpers.setSubmitting(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleErrorToastClose = () => {
        setShowErrorToast(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} lg={6}>
                    {isAuthenticated && storedCredentials ? ( // Show "Logout" button if authenticated
                            <>
                                <Typography variant="h2" sx={{mt:5, mb: 2}}>
                                    You're logged in as <span style={{ color: '#C71B8C' }}>{parsedCredentials?.username}</span>
                                    <br/>
                                    In the <span style={{ color: '#EC8628' }}>{siteLabel} Environment</span>
                                </Typography>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            handleRemoveLocalStorage();
                                            setIsAuthenticated(false);
                                        }}
                                    >
                                        Logout
                                    </Button>
                                </AnimateButton>
                            </>
                        ) :
                        (
                            <>
                                <Typography variant="h1" sx={{mb: 2, mt: 10}}>
                                    Login to use portal
                                </Typography>
                                {loading && <CircularSpinner/>}
                                <Formik
                                    initialValues={{
                                        userName: '',
                                        password: ''
                                    }}
                                    validationSchema={Yup.object({
                                        userName: Yup.string().max(255).required('User name is required'),
                                        password: Yup.string().max(255).required('Password is required')
                                    })}
                                    onSubmit={(values, formikHelpers) => handleAuthentication(values, formikHelpers)} // Pass formikHelpers
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          touched,
                                          values,
                                      }) => (

                                        <form noValidate onSubmit={handleSubmit} {...others}>


                                            <FormControl fullWidth error={Boolean(touched.userName && errors.userName)}
                                                         sx={{...theme.typography.customInput}}>
                                                <InputLabel
                                                    htmlFor="outlined-adornment-email-login">Username</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-login"
                                                    type="text"
                                                    value={values.userName}
                                                    name="userName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Username"
                                                    inputProps={{}}
                                                />
                                                {touched.userName && errors.userName && (
                                                    <FormHelperText error
                                                                    id="standard-weight-helper-text-userName-login">
                                                        {errors.userName}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                            <FormControl fullWidth error={Boolean(touched.password && errors.password)}
                                                         sx={{...theme.typography.customInput}}>
                                                <InputLabel
                                                    htmlFor="outlined-adornment-password-login">Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password-login"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                    inputProps={{}}
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText error
                                                                    id="standard-weight-helper-text-password-login">
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                            {errors.submit && (
                                                <Box sx={{mt: 3}}>
                                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                                </Box>
                                            )}

                                            <Box sx={{mt: 2}}>
                                                <AnimateButton>
                                                    <Button disableElevation disabled={isSubmitting} fullWidth
                                                            size="large"
                                                            type="submit" variant="contained" color="secondary">
                                                        Sign in
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </form>

                                    )}
                                </Formik>
                            </>
                        )}
                </Grid>
            </Grid>
            <ErrorToast open={showErrorToast} onClose={handleErrorToastClose} message={"Authentication failed!"}/>
        </>
    );
};

export default AuthenticationLogin;
