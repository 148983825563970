import {useContext} from "react";
import {parsedCredentials} from "./auth/AuthContext";
import APIRequestHeader from "../APIRequestHeader";

export const useAuthHeaders = () => {
    const requestHeaderCors = useContext(APIRequestHeader);
    return {
        Authorization: `Basic ${btoa(`${parsedCredentials?.username}:${parsedCredentials?.password}`)}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        SelfServe: requestHeaderCors,
    };
};
