import React, {useContext, useEffect, useState} from 'react';
import {useField} from 'formik';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import axios from 'axios';
import {useAuthHeaders} from './AuthHeaders';
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import ApiUrlContext from "../ApiUrlContext";

const LedgerDropdown = ({name, maxHeight,textSize,label, ...props}) => {
    const [ledgerData, setLedgerData] = useState([]);
    const [field, meta, helpers] = useField(name);
    const isError = Boolean(meta.touched && meta.error);
    const authHeaders = useAuthHeaders();
    const {setIsAuthenticated} = useContext(AuthContext);
    const apiUrl = useContext(ApiUrlContext);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const requestData = {list: 'ledgers'};

                const config = {
                    url: `${apiUrl}/query`,
                    method: 'POST',
                    data: requestData,
                    headers: authHeaders,
                };

                const response = await axios(config);
                setLedgerData(response.data.ledgers);
            } catch (error) {
                if (error.response?.data?.error?.code === "401") {
                    // Handle 401 Unauthorized response
                    setIsAuthenticated(false);
                    handleRemoveLocalStorage();
                }
                console.error('Error fetching data:', error);
            }
        };

        if (apiUrl) {
            fetchData();
        }

    }, [apiUrl]);

    return (
        <FormControl fullWidth error={isError} variant="outlined">
            <InputLabel id="ledger-label">{label}</InputLabel>
            <Select
                labelId="ledger-label"
                label={label}
                id="ledger-select"
                value={field.value}
                onChange={(event) => {
                    helpers.setValue(event.target.value);
                }}
                variant="outlined"
                MenuProps={{
                    style: {
                        maxHeight: maxHeight,
                    },
                }}
                sx={{
                    padding: '5.5px 1.5px!important',
                }}
                {...props}
            >
                {ledgerData.map(ledger => (
                    <MenuItem key={ledger.id} value={ledger.ledgerId}
                              sx={{fontSize: textSize || 'inherit'}}
                    >
                        {ledger.description || ledger.ledgerId}
                    </MenuItem>
                ))}
            </Select>
            {isError && <div style={{color: '#f44336', fontSize:"0.75rem", marginLeft:"14px", marginTop:"4px"}}>{meta.error}</div>}
        </FormControl>
    );
}

export default LedgerDropdown;
