import React from "react";

// Material UI Components
import {Box, Chip, Divider, Grid, Stack, Typography} from '@mui/material';
import SubCard from "./cards/SubCard";
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import {formatDateToMMDDYYYY} from "../store/dateStore";

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};

const TransactionSummary = ({selectedTransaction}) => {
    const transactionData = selectedTransaction.credit || selectedTransaction.debit || selectedTransaction.transfer || selectedTransaction.collected || selectedTransaction.received;


    return (<Box maxHeight="90vh" overflowY="auto">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubCard title={`Payment Reference: ${transactionData.reference}`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body2">
                                            <CalendarTodayTwoToneIcon sx={detailsIconSX}/> Book Date: {formatDateToMMDDYYYY(transactionData.created)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4} container justifyContent="flex-end" alignItems="center">
                                        <Stack spacing={3}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="subtitle1">Payment status:</Typography>
                                                <Chip label={transactionData.state} />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body2">
                                            {transactionData.finished && (
                                                <>
                                                    <CalendarTodayTwoToneIcon sx={detailsIconSX}/> Finished Date: {formatDateToMMDDYYYY(transactionData.finished)}
                                                </>
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack spacing={2}>
                                            <Typography variant="h4">Payment Details</Typography>
                                            <Stack spacing={0}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography
                                                        variant="subtitle1"> Amount:</Typography>
                                                    <Typography variant="body2">
                                                        {transactionData.amount} {transactionData.currency}
                                                    </Typography>
                                                </Stack>
                                                {transactionData.fx && Object.keys(transactionData.fx).length > 0 && transactionData.fx.amount && (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Typography variant="subtitle1">FX Amount:</Typography>
                                                        <Typography
                                                            variant="body2">{transactionData.fx.amount} {transactionData.fx.currency}</Typography>
                                                    </Stack>
                                                )}
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography variant="subtitle1">Transaction ID: </Typography>
                                                    <Typography variant="body2">{transactionData.id}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Stack>
                                                {transactionData.text && transactionData.text.description && (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Typography variant="subtitle1">Description</Typography>
                                                        <Typography
                                                            variant="body2">{transactionData.text.description}</Typography>
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        </Box>
    )
        ;
};

export default TransactionSummary;