import React, { useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Grid,
    Button, Modal,
} from "@mui/material";
import { currencyFlags } from "../../ui-component/CurrencyOptions";
import CopiedDataToast from "../../ui-component/alerts/CopiedDataToast";
import CreateCredit from "../CreateCredit";

const FxQuoteResponseDisplay = ({ response, request }) => {
    const { expiry, id, rate, inverse, currency } = response;
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [copiedData, setCopiedData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const readableExpiry = new Date(expiry).toLocaleString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    const handleCopyClick = () => {
        navigator.clipboard.writeText(id);
        setCopiedData(id);
        setIsSnackbarOpen(true);
    };

    const renderCurrencyPair = (fromCurrency, toCurrency, rate) => {
        const fromFlag = currencyFlags[fromCurrency] || "";
        const toFlag = currencyFlags[toCurrency] || "";

        return (
            <TableRow key={`${fromCurrency}-${toCurrency}`}>
                <TableCell>
                    <Typography variant="h3">
                        {fromFlag && `${fromFlag} `}{fromCurrency} ➡️ {toFlag && `${toFlag} `}{toCurrency}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="h3">
                        <strong>{rate}</strong>
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    if (response.error) {
        return (
            <Typography variant="body2" color="error">
                {`Error: ${response.error.code} - ${response.error.message}`}
            </Typography>
        );
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" gutterBottom sx={{ marginTop: "2vh" }}>
                        🔄 Currency Exchange Rates
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        🕒 Expiry: {readableExpiry}
                    </Typography>
                    <Typography variant="h4" onClick={handleCopyClick}>
                        📋 ID: <span style={{ cursor: "pointer", textDecoration: "underline" }}>{id}</span>
                    </Typography>

                    <Box mt={2} sx={{ backgroundColor: "#B0C0C8", borderRadius: "8px", overflow: "hidden" }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: "#005681" }}>
                                    <TableCell>
                                        <Typography variant="h3" color={"white"}>
                                            Currency Pair
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h3" color={"white"}>
                                            Rate
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderCurrencyPair("USD", currency, rate)}
                                {renderCurrencyPair(currency, "USD", inverse)}
                            </TableBody>
                        </Table>
                    </Box>
                    <Button onClick={handleOpenModal}> Book Transaction </Button>
                </Grid>
            </Grid>
            <CopiedDataToast open={isSnackbarOpen} onClose={() => setIsSnackbarOpen(false)} copiedData={copiedData} />
            <>
                <Modal open={isModalOpen} onClose={handleCloseModal}>
                    <Box  sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'fit-content',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        bgcolor: 'white',
                        borderRadius: '16px',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                        p: 4,
                        outline: 'none',
                    }}>
                        <Typography variant={'h2'}> Send FX Credit</Typography>
                        <CreateCredit
                            FxQuoteId={id}
                            LedgerId={request.ledgerId}
                            EntityId={request.entityId}
                            Currency={response.currency}
                        />
                    </Box>
                </Modal>
            </>
        </Box>

    );
};

export default FxQuoteResponseDisplay;