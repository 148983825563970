import React, {useContext, useEffect, useState} from 'react';
// materialUI imports
import {Box, Grid, Typography} from '@mui/material';

// project imports
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import ApiUrlContext from "../ApiUrlContext";
import CustomFormField from '../ui-component/CustomFormField';
import CustomDropdown from '../ui-component/CustomDropdown';
import {useAuthHeaders} from "./AuthHeaders";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import CustomCurrencyValueInput from "../ui-component/CustomCurrencyValueInput";
import {ResponseDisplay} from "./response/ResponseDisplay";

// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import LedgerDropdown from "./LedgerDropdown";

// ============================|| Create - Debit ||============================ //

const CreateTransfer = () => {
    const responseRef = React.useRef(null);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [response, setResponse] = useState(null);
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();


    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [response]);
    const handleSubmit = async (values, {setSubmitting}) => {
        const requestData = {
            currency: values.currency,
            amount: values.amount,
            description: values.description,
            reference: values.reference,
            fromLedgerId: values.fromLedgerId,
            toLedgerId: values.toLedgerId,
        };

        try {
            const options = {
                method: 'POST',
                url: `${apiUrl}/transfer`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            setResponse(response.data);
            setIsSubmitted(true);
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box mt={2}>
            <Typography variant="h6" component="h2" gutterBottom>
                Create Transfer
            </Typography>

            <Formik
                initialValues={{
                    currency: 'USD',
                    amount: '',
                    description: '',
                    reference: '',
                    fromLedgerId: '',
                    toLedgerId: '',
                }}
                validationSchema={Yup.object().shape({
                    currency: Yup.string().required('Currency is required'),
                    amount: Yup.string().required('Amount is required'),
                    description: Yup.string().required('Description is required'),
                    reference: Yup.string().required('Reference is required'),
                    fromLedgerId: Yup.string().required('From Ledger ID is required'),
                    toLedgerId: Yup.string().required('To Ledger ID is required'),
                })}
                onSubmit={handleSubmit}
            >
                {({values, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting, touched, errors}) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LedgerDropdown
                                    name="setFieldValue"
                                    label="From Ledger ID"
                                    value={values.fromLedgerId}
                                    onChange={(event) => setFieldValue('fromLedgerId', event.target.value)}
                                    maxHeight={"50vh"}
                                    textSize={"1rem"}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <LedgerDropdown
                                    name="toLedgerId"
                                    label="To Ledger ID"
                                    value={values.toLedgerId}
                                    onChange={(event) => setFieldValue('toLedgerId', event.target.value)}
                                    maxHeight={"50vh"}
                                    textSize={"1rem"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                    name="currency"
                                    label="Currency"
                                    value={values.currency}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'USD', label: 'USD'},
                                        // Add more currency options as needed
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomCurrencyValueInput
                                    name="amount"
                                    label="Amount"
                                    value={values.amount}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="reference"
                                    label="Reference"
                                    value={values.reference}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <APIRequestButton isSubmitting={isSubmitting} isSubmitted={isSubmitted} showRefreshButton={true} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2} ref={responseRef}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateTransfer;
