import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {DataGrid} from '@mui/x-data-grid';
import {IconCashBanknote} from "@tabler/icons";
import {Box, Button, IconButton, Modal, Tooltip, Typography, useMediaQuery} from "@mui/material";
import CreateCredit from "./CreateCredit";
import CreateDebit from "./CreateDebit";
import AnimateButton from "../ui-component/extended/AnimateButton";
import ApiUrlContext from "../ApiUrlContext";
import {useAuthHeaders} from "./AuthHeaders";
import {useTheme} from "@mui/material/styles";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import RefreshQueryToolbar from "../ui-component/datagrid/RefreshQueryToolbar";
import CloseIcon from "@mui/icons-material/Close";


const EntityTable = () => {
    // State to store the data from the API response
    const [entityData, setEntityData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [currentEntity, setCurrentEntity] = useState(null);
    const [modalSelectedEntity, setModalSelectedEntity] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const apiUrl = useContext(ApiUrlContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // This checks if the current screen width is "large" or greater.
    const authHeaders = useAuthHeaders();

    const closeModal = (event, reason) => {
        setIsModalOpen(false);
        setSelectedOption('');
        setModalSelectedEntity(null);
    };

    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };
    // Fetch data using Axios when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestData = {list: 'entities'};

                const config = {
                    url: `${apiUrl}/query`,
                    method: 'POST',
                    data: requestData,
                    headers: authHeaders,
                };

                const response = await axios(config);
                setEntityData(response.data.entities);

            } catch (error) {
                if (error.response && error.response.data.error.code === "401") {
                    // Handle 401 Unauthorized response
                    setIsAuthenticated(false);
                    handleRemoveLocalStorage();
                }
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [isAuthenticated, refreshFlag]);

    // Define the columns for the DataGrid
    const columns = [
        {field: 'reference', headerName: 'Entity Reference', flex: 1},
        {field: 'state', headerName: 'State', flex: 1},
        {
            field: 'sendMoney',
            headerName: 'Initiate Transaction',
            flex: 1,
            sortable: false,
            align: 'right',
            headerAlign: 'right',

            renderCell: (params) => (
                <Tooltip title="Initiate Transaction">
                    <div>
                        <IconCashBanknote
                            onClick={() => {
                                setCurrentEntity(params.row);
                                setIsModalOpen(true);
                            }}
                        />
                    </div>
                </Tooltip>
            ),

        }
    ];

    // Function to handle the selection of Debit or Credit in the modal
    const handleOptionSelection = (option) => {
        setSelectedOption(option);
        setModalSelectedEntity(currentEntity);
    };


    return (
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                rows={Array.isArray(entityData) ? entityData : []}
                columns={columns}
                getRowId={(row) => row.reference}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                slots={{
                    toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh}/>,
                }}
            />
            <Modal open={isModalOpen} onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    closeModal();
                }
            }}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: '#fff',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    padding: '32px',
                    borderRadius: '8px',
                    width: isLargeScreen ? '60vw' : '80vw',
                    maxHeight: '95vh',
                    overflowY: 'auto'
                }}>
                    {/* Title Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between', // Align the heading and close button at the ends
                            alignItems: 'center', // Vertically center the content
                            marginBottom: '16px', // Add spacing between the heading and close button
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            Select Transaction Type
                        </Typography>
                        <IconButton onClick={closeModal} aria-label="close" size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {/* Content */}
                    {selectedOption === 'Credit' &&
                        <CreateCredit entity={modalSelectedEntity} onChangeEntity={setModalSelectedEntity}/>
                    }
                    {selectedOption === 'Debit' &&
                        <CreateDebit entity={modalSelectedEntity} onChangeEntity={setModalSelectedEntity}/>
                    }
                    {selectedOption === '' && (
                        <div>
                            <Box mt={2}>
                                <AnimateButton>
                                    <Button variant="contained" size="large" fullWidth
                                            onClick={() => handleOptionSelection('Debit')}>
                                        Debit
                                    </Button>
                                </AnimateButton>
                            </Box>
                            <Box mt={2}>
                                <AnimateButton>
                                    <Button variant="contained" size="large" fullWidth
                                            onClick={() => handleOptionSelection('Credit')}>
                                        Credit
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default EntityTable;