import React, {useState} from "react";

// material-ui
import {Grid} from "@mui/material";


// project imports
// third-party
import CreateDebit from "./requests/CreateDebit";
import QueryDebit from "./requests/QueryDebit";
import ApiRequestCard from "./ui-component/cards/ApiRequestCard";


// ============================|| PAGE - Debit ||============================ //
const Debit = () => {


    return (
        <Grid container spacing={2} justifyContent="center">
            <ApiRequestCard
                title={"Look Up An Debit"}
                buttonText={"Search Debits"}
                component={<QueryDebit/>}
                description={"This request will produce a response containing the latest details related to the specified debit."}
            />
            <ApiRequestCard
                title={"Create A Debit"}
                buttonText={"Initiate Debit"}
                component={<CreateDebit/>}
                description={"This request will start the process of debiting funds from a payee (the\n" +
                    " specified entity) to the payer (the client). This will be executed using the most appropriate \n" +
                    " method between the two parties within the specified timeframe."}
            />
        </Grid>
    );
};

export default Debit;

