import React, {useState} from "react";

// material-ui
import {Button, Grid, Typography} from "@mui/material";


// project imports
import MainCard from "./ui-component/cards/MainCard";
import AnimateButton from "./ui-component/extended/AnimateButton";


// third-party
import {IconArrowDown, IconCirclePlus} from "@tabler/icons";
import GetLedgers from "./requests/GetLedgers";
import CreateLedger from "./requests/CreateLedger";
import CreateFBOLedger from "./requests/CreateFBOLedger";

// ============================|| PAGE - Ledger ||============================ //
const Ledger = () => {
    const [isHidden, setIsHidden] = useState(true); // state to control hidden section
    const [isHidden2, setIsHidden2] = useState(true); // state to control hidden section
    const [isHidden3, setIsHidden3] = useState(true); // state to control hidden section

    const handleToggleHiddenSection = () => {
        setIsHidden(!isHidden);
    };
    const handleToggleHiddenSection2 = () => {
        setIsHidden2(!isHidden2);
    };
    const handleToggleHiddenSection3 = () => {
        setIsHidden3(!isHidden3);
    };


    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} lg={10} xl={8}>
                <MainCard
                    title={"Ledger Requests"}
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: "18px"}}>
                                    These request will produce responses containing the latest details related to
                                    Ledgers and allow for the creation of a new ledger.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleToggleHiddenSection}
                                        sx={{width: "100%", fontSize: "18px"}}
                                        endIcon={isHidden ? <IconCirclePlus/> : <IconArrowDown/>}
                                    >
                                        Get Ledgers
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {!isHidden &&
                                <Grid item xs={12}>
                                    <GetLedgers/>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleToggleHiddenSection2}
                                        sx={{width: "100%", fontSize: "18px"}}
                                        endIcon={isHidden2 ? <IconCirclePlus/> : <IconArrowDown/>}
                                    >
                                        Create Ledger
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {!isHidden2 &&
                                <Grid item xs={12}>
                                    <CreateLedger/>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleToggleHiddenSection3}
                                        sx={{width: "100%", fontSize: "18px"}}
                                        endIcon={isHidden3 ? <IconCirclePlus/> : <IconArrowDown/>}
                                    >
                                        Create FBO Ledger
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {!isHidden3 &&
                                <Grid item xs={12}>
                                    <CreateFBOLedger/>
                                </Grid>
                            }
                        </Grid>
                    }
                />
            </Grid>
        </Grid>

    );
};

export default Ledger;

