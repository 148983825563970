import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";

import {IconArrowDown, IconCirclePlus} from "@tabler/icons";
import MainCard from "./MainCard";
import AnimateButton from "../extended/AnimateButton";


// ============================|| UI COMPONENT - API REQUEST CARD  ||============================ //
const ApiRequestCard = ({title, description, component, buttonText}) => {
    const [isHidden, setIsHidden] = useState(true);

    const handleToggleHiddenSection = () => {
        setIsHidden(!isHidden);
    };

    return (
        <Grid item xs={12} lg={10} xl={8}>
            <MainCard
                title={title}
                children={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{fontSize: "18px"}}>{description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    onClick={handleToggleHiddenSection}
                                    sx={{width: "100%", fontSize: "18px"}}
                                    endIcon={isHidden ? <IconCirclePlus/> : <IconArrowDown/>}
                                >
                                    {buttonText}
                                </Button>
                            </AnimateButton>
                        </Grid>
                        {!isHidden && (
                            <Grid item xs={12}>
                                {component}
                            </Grid>
                        )}
                        {/*<Grid item xs={12}>*/}
                        {/*    <AnimateButton>*/}
                        {/*        <Button*/}
                        {/*            variant="contained"*/}
                        {/*            color="secondary"*/}
                        {/*            sx={{width: "100%", fontSize: "18px"}}*/}
                        {/*            endIcon={<IconFileCode/>}*/}
                        {/*        >*/}
                        {/*            Documentation*/}
                        {/*        </Button>*/}
                        {/*    </AnimateButton>*/}
                        {/*</Grid>*/}
                    </Grid>
                }
            />
        </Grid>
    );
};


export default ApiRequestCard;