// assets
import {IconCoins, IconReplace, IconTransferOut, IconWallet} from '@tabler/icons';

// constant
const icons = {
    IconWallet,
    IconCoins,
    IconTransferOut,
    IconReplace
};

// ==============================|| Actions MENU ITEMS ||============================== //

const actions = {
    id: 'actions',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'credit',
            title: 'Credit',
            type: 'item',
            url: '/credit',
            icon: icons.IconWallet,
            breadcrumbs: false
        },
        {
            id: 'debit',
            title: 'Debit',
            type: 'item',
            url: '/debit',
            icon: icons.IconCoins,
            breadcrumbs: false
        },
        {
            id: 'transfer',
            title: 'Transfer',
            type: 'item',
            url: '/transfer',
            icon: icons.IconTransferOut,
            breadcrumbs: false
        },
        {
            id: 'quote',
            title: 'FX Quote',
            type: 'item',
            url: '/quote',
            icon: icons.IconReplace,
            breadcrumbs: false,
        },
    ]
};

export default actions;
