//3rd party imports
import {useSelector} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {Route, Routes} from "react-router-dom";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers'

// routing
// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import MainLayout from "./layout";
import Login  from "./Login";
import Entity from "./Entity";
import Ledger from "./Ledger";
import Credit from "./Credit";
import Debit from "./Debit";
import Transfer from "./Transfer";
import {AuthProvider} from "./requests/auth/AuthContext";
import Welcome from "./Welcome";
import Quote from "./Quote";
import {useContext} from "react";
import ApiUrlContext from "./ApiUrlContext";
import APIRequestHeader from "./APIRequestHeader";
import TransactionHistory from "./requests/TransactionHistory";
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthProvider>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline/>
                        <NavigationScroll>
                            <MainLayout>
                                <Routes>
                                    <Route path="/" element={<Login/>}/>
                                    <Route path="/authentication" element={<Login/>}/>
                                    <Route path="/entity" element={<Entity/>}/>
                                    <Route path="/ledger" element={<Ledger/>}/>
                                    <Route path="/credit" element={<Credit/>}/>
                                    <Route path="/debit" element={<Debit/>}/>
                                    <Route path="/transfer" element={<Transfer/>}/>
                                    <Route path="/welcome" element={<Welcome/>} />
                                    <Route path="/quote" element={<Quote/>} />
                                    <Route path="/transaction-history" element={<TransactionHistory/>} />
                                </Routes>
                            </MainLayout>
                        </NavigationScroll>
                    </ThemeProvider>
                </AuthProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    );
};

export default App;