import React, {useContext, useState} from 'react';
// materialUI imports
import {Box, Typography} from '@mui/material';

// project imports
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import {ResponseDisplay} from "./response/ResponseDisplay";
import CustomDropdown from "../ui-component/CustomDropdown";
import CustomFormField from "../ui-component/CustomFormField";
import {useAuthHeaders} from "./AuthHeaders";
import ApiUrlContext from "../ApiUrlContext";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";

// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';


// ============================|| Request - Ledger ||============================ //

const CreateLedgerSection = () => {
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();

    const InfoText = ({text}) => showInfo ?
        <Typography variant="caption" sx={{color: '#C41C90'}}>
            {text}
        </Typography> : null;

    const handleSubmit = async (values) => {
        const requestData = {
            currency: values.currency,
            note: values.note,
            accounts: values.accounts,
        };

        try {
            const response = await axios.post(`${apiUrl}/ledger`, requestData, {
                headers: authHeaders,
            });
            setResponse(response.data);
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error('Error:', error);
        }
    };

    return (
        <Box mt={2}>
            <div
                onClick={() => setShowInfo(prev => !prev)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '5px'
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                    Need Help?
                </Typography>
            </div>

            <Formik
                initialValues={{
                    currency: 'USD',
                    note: '',
                    accounts: 1,
                }}
                validationSchema={Yup.object().shape({
                    currency: Yup.string().required('Currency is required'),
                    note: Yup.string().trim().notOneOf([''], 'Note cannot be empty').required('Note is required'),
                    accounts: Yup.number().required('Accounts is required'),
                })}
                onSubmit={handleSubmit}
            >
                {({values, handleChange, handleBlur, isSubmitting, touched, errors}) => (
                    <Form>
                        <CustomDropdown
                            name="currency"
                            label="Currency"
                            value={values.currency}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            options={[
                                {value: 'USD', label: 'USD'},
                                // Add more currency options as needed
                            ]}
                        />
                        <InfoText text={'An optional note / description for this ledger.'}/>
                        <CustomFormField
                            name="note"
                            label="Note"
                            value={values.note}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                        <InfoText
                            text={"The number of sequential accounts requested. If not specified, this defaults to 1."}
                        />
                        <CustomFormField
                            name="accounts"
                            label="Accounts"
                            value={values.accounts}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />

                        <APIRequestButton isSubmitting={isSubmitting} showRefreshButton={true}/>
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateLedgerSection;