import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {DataGrid} from '@mui/x-data-grid';
import {useAuthHeaders} from "./AuthHeaders";
import {IconClipboard} from "@tabler/icons";
import CopiedDataToast from "../ui-component/alerts/CopiedDataToast";
import ApiUrlContext from "../ApiUrlContext";
import {Tooltip} from "@mui/material";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import RefreshQueryToolbar from "../ui-component/datagrid/RefreshQueryToolbar";

const LedgerTable = () => {
    const [ledgerData, setLedgerData] = useState([]);
    const [copiedData, setCopiedData] = React.useState('');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();


    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestData = {list: 'ledgers'};

                const config = {
                    url: `${apiUrl}/query`,
                    method: 'POST',
                    data: requestData,
                    headers: authHeaders,
                };

                const response = await axios(config);
                setLedgerData(response.data.ledgers);
            } catch (error) {
                if (error.response && error.response.data.error.code === "401") {
                    // Handle 401 Unauthorized response
                    setIsAuthenticated(false);
                    handleRemoveLocalStorage();
                }
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [refreshFlag]);

    const columns = [
        {
            field: 'actions',
            headerName: '',
            flex: 0.25,
            renderCell: (params) => {
                return (
                    <Tooltip title="Copy a Ledger ID">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconClipboard
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    const selectedLedger = params.row;
                                    const copiedString = selectedLedger.ledgerId.toString();
                                    navigator.clipboard.writeText(copiedString);
                                    setCopiedData(copiedString);
                                    setIsSnackbarOpen(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: 'ledgerId',
            headerName: 'Ledger ID',
            flex: 1.5,
            renderCell: (params) => {
                const description = params.row.description;
                if (description && description.trim() !== '') {
                    return description;
                }
                return params.row.ledgerId;
            }
        },        {field: 'aba', headerName: 'ABA', flex: .5, headerAlign: 'right', align: 'right', type: 'number'},
        {field: 'fbo', headerName: 'FBO', flex: .5, headerAlign: 'right', align: 'right'},
        {field: 'state', headerName: 'State', flex: .5, headerAlign: 'right', align: 'right'},
        {field: 'balance', headerName: 'Balance', flex: .5, headerAlign: 'right', align: 'right', type: 'number'},
        {field: 'currency', headerName: 'Currency', flex: .5, headerAlign: 'right', align: 'right'},
        {field: 'available', headerName: 'Available', flex: .5, headerAlign: 'right', align: 'right', type: 'number'}
    ];


    return (
        <div style={{height: '100%', width: '100%'}}>
            <CopiedDataToast
                open={isSnackbarOpen}
                onClose={() => setIsSnackbarOpen(false)}
                copiedData={copiedData}
            />
            <DataGrid
                rows={Array.isArray(ledgerData) ? ledgerData : []}
                columns={columns}
                getRowId={(row) => row.ledgerId}
                slots={{
                    toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh}/>,
                }}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableRowSelectionOnClick
                onClipboardCopy={(copiedString) => {
                    setCopiedData(copiedString);
                    setIsSnackbarOpen(true);
                }}
            />
        </div>
    );
}

export default LedgerTable;