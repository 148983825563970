import dashboard from './dashboard';
import pages from "./pages";
import actions from "./actions";


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard,actions,pages]
};

export default menuItems;
