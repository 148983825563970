import {styled} from "@mui/system";
import {DataGrid} from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-toolbarContainer': {
        marginBottom: '16px',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        color: '#FFF',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '.75rem',
        },
    },
}));