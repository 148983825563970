import React from 'react';
import { Box, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import {NumericFormat} from 'react-number-format';

const CustomCurrencyValueInput = ({ name, label, value, handleChange, handleBlur, touched, errors }) => {
    const formatCurrency = (value) => {
        if (typeof value === 'string') {
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Format currency value with comma separators
        }
        return value;
    };

    const handleValueChange = (values) => {
        handleChange({
            target: {
                name: name,
                value: values.floatValue !== undefined ? values.floatValue.toString() : '', // Store the numeric value as a string
            },
        });
    };

    return (
        <Box>
            <FormControl fullWidth variant="outlined" error={touched[name] && Boolean(errors[name])} sx={{ mt: 1 }}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
                <NumericFormat
                    id={name}
                    name={name}
                    value={formatCurrency(value)}
                    onValueChange={handleValueChange}
                    customInput={OutlinedInput}
                    sx={{ padding: '5.5px 11.5px!important' }}
                    thousandSeparator
                    prefix="$"
                    label={label}
                />
                {touched[name] && errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default CustomCurrencyValueInput;