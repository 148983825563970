import React, {useState} from "react";

// material-ui
import {Grid} from "@mui/material";

// project imports
import ApiRequestCard from "./ui-component/cards/ApiRequestCard";
import CreateTransfer from "./requests/CreateTransfer";

// third-party



// ============================|| PAGE - Transfer ||============================ //
const Transfer = () => {


    return (
        <Grid container spacing={2} justifyContent="center">
            <ApiRequestCard
                title={"Transfer Funds Between Ledgers"}
                buttonText={"Initiate Transfer"}
                component={<CreateTransfer/>}
                description={"This request will initiate a transfer of funds between two ledgers."}
            />
        </Grid>
    );
};

export default Transfer;

