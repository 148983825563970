import React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

const CopiedDataToast = ({ open, onClose, copiedData }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert severity="info" sx={{ width: '100%', mt: 1 }}>
                <AlertTitle>Copied data:</AlertTitle>
                <code
                    style={{
                        display: 'block',
                        maxHeight: 200,
                        overflow: 'auto',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {copiedData}
                </code>
            </Alert>
        </Snackbar>
    );
};

export default CopiedDataToast;