import React, {createContext, useEffect, useState} from 'react';

// ============================|| MakePublic Stored Credentials and parsed Credentials  - LOGIN ||============================ //
export const storedCredentials = localStorage.getItem("authCredentials");
export const parsedCredentials = JSON.parse(storedCredentials);
export const handleRemoveLocalStorage = () => {
    localStorage.removeItem('authCredentials');
};
export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const storedCredentials = localStorage.getItem('authCredentials');
        const parsedCredentials = storedCredentials ? JSON.parse(storedCredentials) : null;
        const hasValidCredentials = parsedCredentials && parsedCredentials.username && parsedCredentials.password;
        setIsAuthenticated(hasValidCredentials);
    }, []);

    return (
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated}}>
            {children}
        </AuthContext.Provider>
    );
};
