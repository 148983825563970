import { useContext } from 'react';

// material-ui
import { styled, keyframes } from '@mui/system';
import { Box, Tooltip } from '@mui/material';

// project imports
import { AuthContext } from "../../../requests/auth/AuthContext"


// Define the keyframes for pulsating animation
const pulseAnimation = keyframes({
    '0%': {
        transform: 'scale(0.9)',
    },
    '50%': {
        transform: 'scale(1.2)',
    },
    '100%': {
        transform: 'scale(0.9)',
    },
});


const PulsatingDotStyled = styled(Box)(({ theme, isAuthenticated }) => ({
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: isAuthenticated ? 'green' : 'red',
    marginRight: theme.spacing(1),
    animation: `${pulseAnimation} 3s ${theme.transitions.easing.easeInOut} infinite`,
}));

const StatusLight = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <Tooltip title={isAuthenticated ? 'Authenticated' : 'Not Authenticated'} arrow>
            <Box display="flex" alignItems="center">
                <PulsatingDotStyled isAuthenticated={isAuthenticated} />
            </Box>
        </Tooltip>
    );
};

export default StatusLight;