import React from 'react';
// materialUI imports
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material';
import {useTheme} from '@mui/material/styles';

// project imports

// 3rd Party imports
import {Field} from 'formik';

// ============================|| Custom Form Input ||============================ //

const CustomFormField = ({name, label, value, handleChange, handleBlur, touched, errors}) => {
    const theme = useTheme();

    return (
        <FormControl fullWidth sx={{...theme.typography.customInput}} error={Boolean(touched[name] && errors[name])}>
            <InputLabel htmlFor={`outlined-adornment-${name}`}>{label}</InputLabel>
            <Field
                as={OutlinedInput}
                id={`outlined-adornment-${name}`}
                type="text"
                name={name}
                label={label}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <FormHelperText error id={`outlined-adornment-${name}-error`}>
                {touched[name] && errors[name] && errors[name]}
            </FormHelperText>
        </FormControl>
    );
};

export default CustomFormField;