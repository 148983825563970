// GetFxQuote.js
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import CustomDropdown from "../ui-component/CustomDropdown";
import CustomFormField from "../ui-component/CustomFormField";
import ApiUrlContext from "../ApiUrlContext";
import { useAuthHeaders } from "./AuthHeaders";
import { AuthContext, handleRemoveLocalStorage } from "./auth/AuthContext";
import axios from "axios";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { currencyOptions } from "../ui-component/CurrencyOptions";
import FxQuoteResponseDisplay from "./response/FxQuoteResponseDisplay";
import CircularSpinner from "../ui-component/CircularSpinner";
import LedgerDropdown from "./LedgerDropdown";

const GetFxQuote = () => {
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const apiUrl = useContext(ApiUrlContext);
    const { setIsAuthenticated } = useContext(AuthContext);
    const responseRef = useRef(null);
    const authHeaders = useAuthHeaders();

    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    }, [response]);

    const initialFormValues =
        JSON.parse(localStorage.getItem("formData")) || {
            ledgerId: "",
            entityId: "",
            currency: "AED",
        };

    const handleFieldChange = (name, value) => {
        const formData = JSON.parse(localStorage.getItem("formData")) || {};
        formData[name] = value;
        localStorage.setItem("formData", JSON.stringify(formData));
    };

    const InfoText = ({ text }) =>
        showInfo ? (
            <Typography variant="caption" sx={{ color: "#C41C90" }}>
                {text}
            </Typography>
        ) : null;

    const clearResponseAndError = () => {
        setResponse(null);
    };

    const handleSubmit = async (values) => {
        const requestData = {
            ledgerId: values.ledgerId,
            entityId: values.entityId,
            currency: values.currency,
        };

        setIsLoading(true);
        clearResponseAndError();

        try {
            const response = await axios.post(`${apiUrl}/quote`, requestData, {
                headers: authHeaders,
            });
            setResponse({ data: response.data, request: requestData });
            localStorage.removeItem("formData");
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box mt={2}>
            <div
                onClick={() => setShowInfo((prev) => !prev)}
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "5px",
                }}
            >
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    style={{ marginRight: "8px" }}
                >
                    Need Help?
                </Typography>
            </div>

            <Formik
                initialValues={initialFormValues}
                validationSchema={Yup.object().shape({
                    ledgerId: Yup.string().required("Ledger ID is required"),
                    entityId: Yup.string()
                        .trim()
                        .notOneOf([""], "Entity cannot be empty")
                        .required("Entity is required"),
                    currency: Yup.string().required("Currency is required"),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleBlur, isSubmitting, touched, errors,setFieldValue }) => (
                    <Form>
                        <Box mb={1}>
                            <CustomDropdown
                                name="currency"
                                label="Currency"
                                value={values.currency}
                                handleChange={(e) => {
                                    handleChange(e);
                                    handleFieldChange("currency", e.target.value);
                                }}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                options={currencyOptions}
                                maxHeight={"50vh"}
                                textSize={"1rem"}
                            />
                        </Box>
                        <Box mb={1}>
                        <InfoText text={"Ledger Id that funds will be drawn from"} />
                        <LedgerDropdown
                            name="ledgerId"
                            label="ledgerId"
                            value={values.ledgerId}
                            onChange={(event) => setFieldValue('ledgerId', event.target.value)}
                            maxHeight={"50vh"}
                            textSize={"1rem"}
                        />
                        </Box>
                        <InfoText text={"Entity that funds will be drawn from"} />
                        <CustomFormField
                            name="entityId"
                            label="Entity"
                            value={values.entityId}
                            handleChange={(e) => {
                                handleChange(e);
                                handleFieldChange("entityId", e.target.value);
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />

                        {isLoading ? (
                            <CircularSpinner />
                        ) : (
                            <APIRequestButton
                                isSubmitting={isSubmitting}
                                showRefreshButton={false}
                            />
                        )}
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2} ref={responseRef}>
                    <FxQuoteResponseDisplay response={response.data} request={response.request} />
                </Box>
            )}
        </Box>
    );
};

export default GetFxQuote;
