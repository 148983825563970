import React, {useContext, useEffect, useState} from 'react';

// materialUI imports
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material';

// project imports
import CustomCurrencyValueInput from "../ui-component/CustomCurrencyValueInput";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import {ResponseDisplay} from "./response/ResponseDisplay";
import CustomFormField from '../ui-component/CustomFormField';
import CustomDropdown from '../ui-component/CustomDropdown';
import {useAuthHeaders} from './AuthHeaders';
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";
import ApiUrlContext from "../ApiUrlContext";

// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import LedgerDropdown from "./LedgerDropdown";


// ============================|| Create - Credit ||============================ //

const CreateCredit = ({FxQuoteId, LedgerId, EntityId, Currency, entity}) => {
    const responseRef = React.useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [paymentType, setPaymentType] = useState(FxQuoteId ? "Foreign" : "Domestic");
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();

    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [response]);

    const InfoText = ({text}) => showInfo ?
        <Typography variant="caption" sx={{color: '#C41C90'}}>{text}</Typography> : null;

    const handleSubmit = async (values, {setSubmitting}) => {
        const formattedAmount = values.amount.replace(/[^0-9.]/g, ''); // Remove non-digit and non-decimal characters

        const requestData = {
            reference: values.reference,
            ledgerId: values.ledgerId,
            amount: parseFloat(formattedAmount).toFixed(2), // Convert to float and fix decimal places to 2
            currency: values.currency,
            entityId: values.entityId,
            when: values.when,
            text: {description: values.description},
        };

        if (values.account) {
            requestData.account = values.account;
        }
        if (paymentType === 'Foreign' && values.fxQuoteId) {
            requestData.fxQuoteId = values.fxQuoteId;
            requestData.quoteCurrency = values.quoteCurrency;
        }

        try {
            const options = {
                method: 'POST',
                url: `${apiUrl}/credit`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            setResponse(response.data);
            setIsSubmitted(true);

        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };
    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };


    return (
        <Box mt={2}>
            <div
                onClick={() => setShowInfo(prev => !prev)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '5px'
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                    Need Help?
                </Typography>
            </div>

            <Formik
                initialValues={{
                    reference: '',
                    ledgerId: LedgerId ? LedgerId :'',
                    account: undefined,
                    amount: '',
                    currency: Currency ? Currency : 'USD',
                    entityId: EntityId || (entity ? entity.reference : ''),
                    when: 'standard',
                    description: '',
                    fxQuoteId : FxQuoteId ? FxQuoteId : ""
                }}
                validationSchema={Yup.object().shape({
                    reference: Yup.string().required('Reference is required'),
                    account: Yup.string(),
                    amount: Yup.number().required('Amount is required'),
                    ledgerId: Yup.string().required('Ledger Id is required'),
                    currency: Yup.string().required('Currency is required'),
                    entityId: Yup.string().required('Entity Reference is required'),
                    when: Yup.string().required('When is required'),
                    description: Yup.string()
                        .required('Description is required')
                        .max(10, 'Description must be a maximum of 10 characters'),
                })}

                onSubmit={handleSubmit}
            >
                {({values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors, setFieldValue}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel id="paymentTypeLabel">Payment Type</InputLabel>
                                    <Select
                                        labelId="paymentTypeLabel"
                                        label={"Payment Type"}
                                        id="paymentType"
                                        value={paymentType}
                                        onChange={handlePaymentTypeChange}
                                    >
                                        <MenuItem value="Domestic">Domestic Payment</MenuItem>
                                        <MenuItem value="Foreign">Foreign Payment</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {paymentType === 'Foreign' && (
                                <>
                                    <Grid item xs={8}>
                                        <CustomFormField
                                            name="fxQuoteId"
                                            label="Quote ID"
                                            value={values.fxQuoteId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={8}>
                                <InfoText text={"A unique reference for this request. This is used to ensure\n" +
                                    "idempotency. If a request is received with a duplicate\n" +
                                    "reference, it will not be applied twice; you will receive a\n" +
                                    "success response and the latest state."}/>
                                <CustomFormField
                                    name="reference"
                                    label="Reference"
                                    value={values.reference}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <InfoText text={"If specified, the ledger ID that should be used for this request."}/>
                                <LedgerDropdown
                                    name="ledgerId"
                                    label="ledgerId"
                                    value={values.ledgerId}
                                    onChange={(event) => setFieldValue('ledgerId', event.target.value)}
                                    maxHeight={"50vh"}
                                    textSize={"1rem"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <InfoText text={"The raw account number (within the ledger) that should be used for\n" +
                                    "this request. This should only be specified for pooled ledgers."}/>
                                <CustomFormField
                                    name="account"
                                    label="Account"
                                    value={values.account}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <InfoText
                                    text={"The Entity Reference of the payee. Funds will be transferred from the\n" +
                                        "payer (the client) to the payee."}/>
                                <CustomFormField
                                    name="entityId"
                                    label="Entity Reference"
                                    value={values.entityId}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <CustomCurrencyValueInput
                                    name="amount"
                                    label="Amount"
                                    value={values.amount}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <CustomDropdown
                                    name="currency"
                                    label="Currency"
                                    value={values.currency}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'USD', label: 'USD'},
                                        ...(Currency ? [{ value: Currency, label: Currency }] : []),
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <InfoText text={"When the credit should be sent to the payee."}/>
                                <CustomDropdown
                                    name="when"
                                    label="When"
                                    value={values.when}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'standard', label: 'Standard'},
                                        {value: 'sameday', label: 'Same Day'},
                                    ]}
                                />

                            </Grid>
                            <Grid item xs={10}>
                                <CustomFormField
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <APIRequestButton isSubmitting={isSubmitting} isSubmitted={isSubmitted} showRefreshButton={true}/>
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2} ref={responseRef}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateCredit;