import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomDropdown = ({ name, label, value, handleChange, handleBlur, touched, errors, options, maxHeight, textSize }) => {
    const theme = useTheme();
    return (
        <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
            <InputLabel sx={{ fontSize: '1rem' }}>{label}</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                label={label}
                variant="outlined"
                MenuProps={{
                    style: {
                        maxHeight: maxHeight,
                    },
                }}
                sx={{
                    padding: '5.5px 11.5px!important',
                }}
                error={touched[name] && Boolean(errors[name])}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.key}
                        value={option.value}
                        sx={{ fontSize: textSize || 'inherit' }} // Apply text size or inherit from parent
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText error id={`outlined-adornment-${name}-error`}>
                {touched[name] && errors[name] && errors[name]}
            </FormHelperText>
        </FormControl>
    );
};

export default CustomDropdown;
